// import * as React from "react"
import React, { useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import { gsap, Power3 } from 'gsap';
import { EaselPlugin } from 'gsap/EaselPlugin';


import Layout from '../components/Layout';

import '../styles/main.scss';

import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';

import HomepageTestimonials from '../components/Testimonials';
import { ListTLink, animateObjects, newContent } from '../components/Layout';

import SEO from '../components/seo';

import MagneticButton from '../components/MagneticButton';

import HeroVideo from '../assets/herovideo.mp4';



import ProjectsSliderv3 from '../components/ProjectsSlider/ProjectsSliderv3';
import GiveContentTrans from '../components/GiveContentTrans';
import Icon from '../components/Icon';

gsap.registerPlugin(EaselPlugin);

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function IndexPage({ data }) {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  // const projects = data.projects.nodes;

  let heroTitle = useRef(null);

  useEffect(() => {
    function heroTitleAnimation() {
      const tl = gsap.timeline();
      tl.from(heroTitle.firstElementChild.firstElementChild, {
        duration: 0.6,
        skewY: 30,
        y: 50,
        transformOrigin: 'top left',
        opacity: 0,
        ease: Power3.easeIn,
      });
      tl.to(
        heroTitle.firstElementChild.firstElementChild,
        {
          duration: 1,
          y: -200,
          skewY: -30,
          transformOrigin: 'top left',
          opacity: 0,
          ease: Power3.easeIn,
        },
        '>1.6'
      );
      tl.from(
        heroTitle.children[1].firstElementChild,
        {
          duration: 1,
          skewY: 30,
          transformOrigin: 'top left',
          y: 50,
          opacity: 0,
          ease: Power3.easeIn,
        },
        '<0.8'
      );
      tl.from(
        heroTitle.children[2].firstElementChild,
        {
          duration: 1,
          skewY: 30,
          transformOrigin: 'top left',
          y: 50,
          opacity: 0,
          ease: Power3.easeIn,
        },
        '<0.2'
      );
      tl.from(
        heroTitle.children[3].firstElementChild,
        {
          duration: 1,
          skewY: 30,
          transformOrigin: 'top left',
          y: 50,
          opacity: 0,
          ease: Power3.easeIn,
        },
        '<0.2'
      );
    }
    heroTitleAnimation();
  }, []);

  return (
    <Layout>
    <main className="outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Digiagentuur"
          description="Kodulehtede valmistamine, UI/UX disain ja mobiiliapplikatsioonide prototüübid. Kujundame logosid, loome brände ja tegeleme reklaami ning graafilise disainiga. "
          image="/pagecover.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Digiagentuur",
            "description": "Kodulehtede valmistamine, UI/UX disain ja mobiiliapplikatsioonide prototüübid. Kujundame logosid, loome brände ja tegeleme reklaami ning graafilise disainiga. ",
            "url": "https://give.ee/", // or use a dynamic variable
            "inLanguage": "et"
          }}
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Digital agency in Estonia - Innovative & Fast Solutions"
          description="Get top quality UI/UX design, websites, brand itentity and mobile app designs FAST. No-Code development."
          image="/pagecover-en-default.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Digital agency",
            "description": "Get top quality UI/UX design, websites, brand itentity and mobile app designs FAST. No-Code development.",
            "url": "https://give.ee/en/", // or use a dynamic variable
            "inLanguage": "en"
          }}
        />
      </GiveContentTrans>

      <GiveContentTrans language="et">
        <section className="hero">
          <h1 className="hero-title" ref={(el) => (heroTitle = el)}>
            <span className="row1">
              <span>Digiagentuur</span>
            </span>

            <span className="row2">
              <span>Digilahendused</span>
            </span>
            <span className="row3">
              <span>klientidele, kes</span>
            </span>
            <span className="row4">
              <span>
                <strong>hindavad disaini</strong>
              </span>
            </span>
          </h1>

          <h2>Vajad abi kodulehe või UX/UI disainiga? Arutame</h2>

          <a href="https://cal.com/tanels/give-free-calls" className="heading4">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Broneeri tasuta videokõne"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>


        </section>
      </GiveContentTrans>

      <GiveContentTrans language="en">
        <section className="hero">
          <h1 className="hero-title" ref={(el) => (heroTitle = el)}>
            <span className="row1">
              <span>Digital agency</span>
            </span>

            <span className="row2">
              <span>Digital solutions</span>
            </span>
            <span className="row3">
              <span>for clients, who</span>
            </span>
            <span className="row4">
              <span>
                <strong>value design</strong>
              </span>
            </span>
          </h1>
        </section>
      </GiveContentTrans>

      <div className="video">
        <div id="canvas" />
        <div className="wrapper">
          <div className="plane">
            <video
              width="100%"
              height="auto"
              autoPlay
              loop
              muted
              playsInline
              poster=""
            >
              <source src={HeroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <section className="about-us inner-grid">
        <div className="headline">
          <div className="heading3">
            <GiveContentTrans language="et">
              Digiagentuur. <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              UI/UX disain
            </ListTLink>,  <ListTLink
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              kodulehtede valmistamine
            </ListTLink>, veebiarendus ja strateegia. Loome
              rätsepalahendusena valmivad veebilehti, digitooteid ning 
              <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             kujundame logosid
            </ListTLink> ja brände.     
            </GiveContentTrans>
            <GiveContentTrans language="en">
              Digital Agency in Estonia. Get top quality <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              UI/UX design
            </ListTLink>, <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              web design&development
            </ListTLink>, and
              digital strategy. Do you want tailor-made website, digital product design, or <ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
               logo 
            </ListTLink> and
              brand itentity design?
            </GiveContentTrans>
          </div>
        </div>
        <div className="content page-copy">
          <p>
            <GiveContentTrans language="et">
              Kombineerime tehnoloogiat ja UI/UX disaini, et tuua oma klientidele
              tulemusi. Oleme veendunud, et iga disaini investeeritud euro toob
              kordades tagasi. Abistame meeleldi alustavaid ettevõtteid oma
              esimeste sammude tegemisel ja oleme usaldusväärseks partneriks ka
              suurettevõtetele.
            </GiveContentTrans>
            <GiveContentTrans language="en">
              Combining design and technology we deliver designs that bring you
              results. Every euro you invest in design can bring
              you great returns. Are you an emerging startup or a
              enterprise, we're here to assist you every step of the way.
            </GiveContentTrans>
            {/* A/B :D Oleme ideaalseks partneriks suurettevõetele, aga meeleldi abistame ka alustavaid ettevõtteid nende esimeste sammude tegmisel.  */}
          </p>

          <GiveContentTrans language="et">
            <ListTLink
              to="/agentuur/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Loe meist edasi"
                magneticDistance="150"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <ListTLink
              to="/agency/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="About the Agency"
                magneticDistance="150"
                magneticSpeed="0.4"
              />
            </ListTLink>
          </GiveContentTrans>
        </div>
      </section>
      {/*       
      <ProjectsSliderv2 />
<ProjectsSlider /> */}
      <ProjectsSliderv3 />
      <section className="why-us inner-grid section-margin">
        <GiveContentTrans language="et">
          <h2>
            Digiagentuure on palju.
            <br /> <strong>Miks valida meid?</strong>
          </h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>
            Choose the right digital agency
            <br /> <strong>Why we stand out?</strong>
          </h2>
        </GiveContentTrans>

        <div className="columns-3 def-top-margin">
          <div className="column">
            <div className="icon icon64 icon-bg">
            <Icon name="sandclock" />

            </div>
            <p>
              <GiveContentTrans language="et">
                Peame kinni tähtaegadest ja eelarvetest. Teeme kokkuleppeid nii,
                et need oleks selged, arusaadavad ning toetaks ühist visiooni
                lõpptulemuse suhtes.
              </GiveContentTrans>
              <GiveContentTrans language="en">
                We stick to deadlines and budgets, and we make agreements that
                are clear, understandable, and support our shared vision for the
                end result.
              </GiveContentTrans>
            </p>
          </div>
          <div className="column">
            <div className="icon icon64 icon-bg">
            <Icon name="clear" />

            </div>
            <p>
              {' '}
              <GiveContentTrans language="et">
                Teame, mida teeme. Leiame lahendused. Omame enam kui 10 000 tundi töökogemust UI/UX disaini ja veebiarendusega.{' '}
              </GiveContentTrans>
              <GiveContentTrans language="en">
                With extensive and diverse experience, we know what we're doing
                and find solutions to every challenge. In UI/UX design and web development, we have over 10,000 hours of experience.
              </GiveContentTrans>
            </p>
          </div>
          <div className="column">
            <div className="icon icon64 icon-bg">
            <Icon name="communication" />

            </div>
            <p>
              {' '}
              <GiveContentTrans language="et">
                Suhtleme kiirelt ja otsekoheselt. Kuulame, anname nõu ja oskame
                ka ise tagasisidet vastu võtta. Eesmärk on ühine - jõuda parima
                tulemuseni.{' '}
              </GiveContentTrans>
              <GiveContentTrans language="en">
                We communicate quickly and directly, listen to your needs, offer
                advice, and are open to feedback. Our goal is to work together
                and achieve the best possible results.
              </GiveContentTrans>
            </p>
          </div>
        </div>
      </section>
      <HomepageTestimonials />
    </main>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
